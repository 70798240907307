<template>
  <div>
    <nav-bar class="title_top" :icon="'img/backb.png'" :textColor="'#000000'" :backColor="'none'" />
    <div style="padding:0 20px;">
        <p>1.为保障多乐、多乐产品功能实现与安全稳定运行至目的，我们可能会申请或使用操作系统的相关权限；</p>
        <p>2.为保障您的知情权，我们通过下列列表将产品可能申请、使用的相关操作系统权限进行展示，您可以根据实际需要对相关权限进行管理；</p>
        <p>3.根据产品的升级，申请、使用权限的类型与目的可能会有变动，我们将及时根据这些变动对列表进行调整，以确保您及时获悉权限的申请与使用情况；</p>
        <p>4.请您知悉，我们为业务与产品的功能与安全需要，我们可能也会使用第三方及关联方SDK，这些第三方也可能会申请或使用相关操作系统权限；</p>
        <p>5.在使用产品的过程中，您可能会使用第三方开发的H5页面或小程序，这些第三方开发的插件或小程序也可能因业务功能所必需而申请或使用相关操作系统权限；</p>
        <p>6.以下共同适用多乐、多乐，如有单独适用的场景或目的将在下列列表说明。</p>
        <div class="title">Android应用权限列表</div>
        <p style="font-weight:bold; text-indent: unset;">一：拍摄CAMERA</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">功能权限说明：</span>使用拍摄照片和视频</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>上传动态拍摄照片，录制视频时使用</p>
        <p style="font-weight:bold; text-indent: unset;">二：获取应用账户GET_ACCOUNTS</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">功能权限说明：</span>获取设备上已安装的应用创建的账号，请您放心，该权限仅获取应用相关的信息，无法获取您的银行账号密码等个人敏感信息</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于帮助您通过第三方账号登录App</p>
        <p style="font-weight:bold; text-indent: unset;">三：访问精准定位ACCESS_FINE_LOCATION</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">功能权限说明：</span>通过全球定位系统（GPS）或网络位置信息（例如基站和WLAN）获取精准地理位置信息</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于完成安全保障、推荐信息以及基于地理位置的服务（LBS）等相关功能</p>
        <p style="font-weight:bold; text-indent: unset;">四：访问粗略位置ACCESS_COARSE_LOCATION</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">功能权限说明：</span>通过网络位置信息（例如基站和WLAN）获取大致地理位置信息</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于完成安全保障服务及基于地理位置的服务（LBS）等相关功能</p>
        <p style="font-weight:bold; text-indent: unset;">五：录音RECORD_AUDIO</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">功能权限说明：</span>使用麦克风录制音频</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于帮助您完成音视频信息发布等需要使用该权限的相关功能</p>
        <p style="font-weight:bold; text-indent: unset;">六：读取电话状态READ_PHONE_STATE</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">功能权限说明：</span>提供读取手机设备标识等信息，请您放心该权限无法监听、获取您的任何通话内容与信息</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>读取设备通话状态和识别码，识别手机设备ID，保证运营商网络免流服务，用于完成音视频、信息展示、账号登录、安全保障等主要功能</p>
        <p style="font-weight:bold; text-indent: unset;">七：读取外置存储器READ_EXTERNAL_STORAGE</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">功能权限说明：</span>提供读取手机储存空间内数据的功能</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>允许App读取存储中的图片、文件等内容，主要用于帮助您发布信息，在本地记录崩溃日志信息（如有）、清理卸载残留等功能</p>
        <p style="font-weight:bold; text-indent: unset;">八：写入外置存储器WRITE_EXTERNAL_STORAGE</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">功能权限说明：</span>提供写入外部储存功能</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>允许App写入/下载/保存/修改/删除图片、文件、崩溃日志、卸载残留等信息</p>
        <div class="title">iOS 操作系统应用权限列表</div>
        <p style="font-weight:bold; text-indent: unset;">一．NSPhotoLibraryAddUsageDescription</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">对应功能：</span>向相册中添加内容</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>允许App写入/下载/保存/修改/删除图片、文件、崩溃日志等信息</p>
        <p style="font-weight:bold; text-indent: unset;">二．NSPhotoLibraryUsageDescription</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">对应功能：</span>读取相册中内容</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>允许App读取存储中的图片、文件等内容，主要用于帮助您发布信息等功能</p>
        <p style="font-weight:bold; text-indent: unset;">三．NSCameraUsageDescription</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">对应功能：</span>使用摄像头</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于帮助您完成音视频信息发布、完成扫描二维码等需要使用该权限的相关功能</p>
        <p style="font-weight:bold; text-indent: unset;">四．NSLocationAlwaysUsageDescription</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">对应功能：</span>持续获取地理位置</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于完成安全保障、推荐信息以及基于地理位置的服务（LBS）等相关功能</p>
        <p style="font-weight:bold; text-indent: unset;">五．NSLocationWhenInUseUsageDescription</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">对应功能：</span>仅App被使用时获取地理位置</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于完成安全保障、推荐信息以及基于地理位置的服务（LBS）等相关功能</p>
        <p style="font-weight:bold; text-indent: unset;">六．NSLocalNetworkUsageDescription</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">对应功能：</span>请求访问本地网络</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>此App将可发现和连接到您所用网络上的设备</p>
        <p style="font-weight:bold; text-indent: unset;">七．NSMicrophoneUsageDescription</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">对应功能：</span>使用麦克风</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于帮助您完成音视频信息发布等需要使用该权限的相关功能</p>
        <p style="font-weight:bold; text-indent: unset;">八．NSUserTrackingUsageDescription</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">对应功能：</span>跨程序用户追踪</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用场景或目的：</span>用于跨程序用户跟踪，允许后，提供的信息你可能会更感兴趣，请放心本次授权不会获取你在其他应用内的隐私；若拒绝，提供信息的相关性可能会降低</p>

    </div>
  </div>
</template>

<script>
import NavBar from '@/components/navBar.vue';
export default {
  name: 'permissionShow',
  components: {
    NavBar
  },
  data () {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1{
    font-size: 40px;
    text-align: center;
    margin:20px 0;
}
p{
    font-size: 28px;
    text-indent:56px;
    color: rgba(0,0,0,.8);
    word-break: break-all;
}
.title{
    font-size: 32px;
    color: #000;
    font-weight: bold;
    text-align: center;
}
.hint{
  margin: 30px 0 50px;
}
.hint p{
  font-size: 32px;
  color: #000;
  font-weight: bold;
  margin: 10px 0;
}
.title_top{
    position: relative;
    padding-top: 65px;
}
</style>
